import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Endereco, ErroCep, NgxViacepService } from '@brunoc/ngx-viacep';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import * as moment from 'moment';
import { map, startWith } from 'rxjs/operators';
import { Ait } from 'src/app/interfaces/ait';
import { ApiService } from 'src/app/services/api.service';
import { Apiv2Service } from 'src/app/services/api_v2.service';
import { AuthService } from 'src/app/services/auth.service';
import { ProtocoloService } from 'src/app/services/protocolo.service';
import { ImageCompressor } from 'src/tools/image-compressor';
import * as VMasker from 'vanilla-masker/';

@Component({
    selector: 'app-modal-apresentacao',
    templateUrl: './modal-apresentacao.page.html',
    styleUrls: ['./modal-apresentacao.page.scss']
})
export class ModalApresentacaoPage implements OnInit {
    public autenticado = false;
    public tpCategoriaCnh: Array<any> = [
        { TP_CATEGORIA_CNH: 0, NM_TP_CATEGORIA_CNH: 'A' },
        { TP_CATEGORIA_CNH: 1, NM_TP_CATEGORIA_CNH: 'B' },
        { TP_CATEGORIA_CNH: 2, NM_TP_CATEGORIA_CNH: 'AB' },
        { TP_CATEGORIA_CNH: 3, NM_TP_CATEGORIA_CNH: 'C' },
        { TP_CATEGORIA_CNH: 4, NM_TP_CATEGORIA_CNH: 'AC' },
        { TP_CATEGORIA_CNH: 5, NM_TP_CATEGORIA_CNH: 'D' },
        { TP_CATEGORIA_CNH: 6, NM_TP_CATEGORIA_CNH: 'AD' },
        { TP_CATEGORIA_CNH: 7, NM_TP_CATEGORIA_CNH: 'E' },
        { TP_CATEGORIA_CNH: 8, NM_TP_CATEGORIA_CNH: 'AE' }
    ];
    public UFs = [
        'AC',
        'AL',
        'AP',
        'AM',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MT',
        'MS',
        'MG',
        'PA',
        'PB',
        'PR',
        'PE',
        'PI',
        'RJ',
        'RN',
        'RS',
        'RO',
        'RR',
        'SC',
        'SP',
        'SE',
        'TO'
    ];
    public buscarAit: FormGroup;
    public dadosCondutor: FormGroup;
    public imagensForm: FormGroup;
    public ait: Ait;
    public liberarEnviar: boolean = false;
    private endpoint = 'str/aits';
    public imagens = [];
    public arquivos = [];
    private tiposArquivos: Array<any>;

    private movimentos: Array<any>;

    @ViewChild('stepper') stepper;
    @ViewChild('nrCPFRequerente', { read: ElementRef })
    nrCPFRequerente: ElementRef;
    @ViewChild('nrCpfCondutor', { read: ElementRef })
    nrCpfCondutor: ElementRef;
    @ViewChild('nrTelefone1Requerente', { read: ElementRef })
    nrTelefone1Requerente: ElementRef;
    @ViewChild('nrPlaca', { read: ElementRef }) nrPlaca: ElementRef;
    @ViewChild('nrCep', { read: ElementRef }) nrCep: ElementRef;

    maskCpf = [
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/ || /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/
    ]; // ddd.ddd.ddd-dd
    constructor(
        private formBuilder: FormBuilder,
        private api: ApiService,
        private auth: AuthService,
        private toastController: ToastController,
        private loadingController: LoadingController,
        private alertController: AlertController,
        private modalController: ModalController,
        private viacep: NgxViacepService,
        private _protocoloService: ProtocoloService,
        private api2: Apiv2Service,
    ) {}

    async ngOnInit() {
    
        this.buscarAit = this.formBuilder.group({
            nrAit: new FormControl('', Validators.required),
            nrPlaca: new FormControl('', Validators.required),
            nrRenavan: new FormControl('', Validators.required),
            recaptchaReactive: new FormControl(null)
        });
        this.dadosCondutor = this.formBuilder.group({
            
            nmRequerente: new FormControl('', Validators.required),
            nrCPFRequerente: new FormControl(
                '',
                Validators.compose([Validators.required, Validators.minLength(14), Validators.maxLength(18)])
            ),
            nrCnpjRequerente:  new FormControl(''),
            nrTelefone1Requerente: new FormControl(''),

            nmCondutor: new FormControl('', Validators.required),
            nrCpfCondutor: new FormControl(
                '',
                Validators.compose([Validators.required, Validators.minLength(14), Validators.maxLength(18)])
            ),
            nrCnhCondutor: new FormControl(
                '',
                Validators.compose([Validators.required, Validators.minLength(11), Validators.maxLength(11)])
            ),
            nrTelefone1Condutor: new FormControl(''),
            dsEnderecoCondutor: new FormControl(''),
            ufCnhRequerente: new FormControl(''),
            nmCidade: new FormControl('', Validators.required),
            cdCidadeCondutor: new FormControl(''),
            nrCepCondutor: new FormControl('', Validators.compose([Validators.required, Validators.minLength(9), Validators.maxLength(9)])),
            nrImovelCondutor: new FormControl('', Validators.required),
            dsBairroCondutor: new FormControl('', Validators.required),
        });

        console.log(this.dadosCondutor);
        this._load()
        this.imagensForm = this.formBuilder.group({
            checkRegistroID: new FormControl('', Validators.required),
            tpRegistroID: new FormControl('', Validators.required),
            checkRegistroIDCond: new FormControl('', Validators.required),
            checkFormulario: new FormControl('', Validators.required),
            tpRegistroIDCond: new FormControl('', Validators.required)
        });

        if (!this.auth.verificar()) {
            this.autenticado = false;
        } else {
            this.autenticado = true;
            this.buscarAit.patchValue({
                nrPlaca: sessionStorage.getItem('nrPlaca'),
                nrRenavan: sessionStorage.getItem('nrRenavan')
            });
        }

        try {
            this.tiposArquivos = await this.api.get('v2/grl/arquivos/tipos', true);
            if (!(this.tiposArquivos instanceof Array) || this.tiposArquivos.length === 0) {
                (
                    await this.toastController.create({
                        message: 'Tipos de arquivos não cadastrados, entre em contato com o suporte para a solução do problema.',
                        buttons: [{ text: 'OK' }]
                    })
                ).present();
                this.modalController.dismiss();
            }
        } catch (error) {
            console.error(error);
        }
    }

    async buscar(stepper: MatStepper) {
        let loading = await this.loadingController.create({
            message: 'Buscando AIT...'
        });
        loading.present();
        try {
            if (!this.autenticado) {
                await this.auth.autenticar(
                    this.buscarAit.value.nrPlaca.replace('-', '').replace('-', ''),
                    this.buscarAit.value.nrRenavan
                );
            }

            let consulta =
                'placa=' +
                this.buscarAit.value.nrPlaca.replace('-', '') +
                (this.buscarAit.value.nrAit ? '&ait=' + this.buscarAit.value.nrAit : '');
            let retorno = await this.api.get(this.endpoint + '?' + consulta, true);
            this.ait = retorno[0];
            let validacao = await this.validarApresentacao(this.ait.cdAit);

            if (validacao) this.buscarAit.setErrors({ notValid: true });
            else stepper.next();

            loading.dismiss();
        } catch (error) {
            debugger
            loading.dismiss();
            let toast = await this.toastController.create({
                message: 'Não foi possível encontrar o AIT',
                duration: 3000
            });
            toast.present();
        }
    }

    async avancar(stepper: MatStepper) {
        stepper.next();
    }

    carregarImagens(idElemento) {
        var target = document.getElementById(idElemento);
        target.click();
    }

    async aviso() {
        let confirm = await this.alertController.create({
            header: 'Aviso',
            message: 'O envio do documento deve ser digitalizado dentre os formatos suportados (.jpeg)',
            buttons: ['OK']
        });

        confirm.present();
    }

    abreArquivos(e, tipo, tipoCheck) {
        var files = e.target.files;
        const imageCompressor = new ImageCompressor();
        if (!files.length) {
            return;
        }
        for (let file of files) {
            const fileSize = file.size;
            if (file.type === 'application/pdf' && fileSize > 1000000) {
                this.imagensForm.get(tipoCheck).setErrors({filePdfGrande: true});
                return;
            }
            var reader = new FileReader();
            reader.onload = async (e) => {
                if (file.type === 'application/pdf') {
                    this.imagensForm.controls[tipoCheck].setValue(true);
                    const arquivo = {
                        formato: 'pdf',
                        tipo: tipoCheck,
                        baixa_resolucao: this._base64ToArrayBuffer(e.target['result']), 
                        cdTipoArquivo: this.getTPArquivo(tipo)
                    };
                    this.arquivos.push(arquivo); 
                } else {
                    const loading = await this.loadingController.create({
                        message: 'Carregando...',
                        spinner: 'bubbles'
                    });
                    await loading.present();
                    const compressedFile = await imageCompressor.compress(file);
                    await loading.dismiss();
                    const compressedReader = new FileReader();
                    compressedReader.readAsDataURL(compressedFile);
                    compressedReader.onloadend = () => {
                        const base64data = compressedReader.result;
                        this.imagensForm.controls[tipoCheck].setValue(true);
                        const imagem = {
                            formato: 'jpeg',
                            tipo: tipoCheck,
                            baixa_resolucao: this._base64ToArrayBuffer(base64data),
                            cdTipoArquivo: this.getTPArquivo(tipo)
                        };
                        this.arquivos.push(imagem);
                    };
                }
            };
            reader.readAsDataURL(file);
        }
    }

    public excluirArquivo(tipoCheck: string) {
        this.arquivos = this.arquivos.filter(item => item.tipo !== tipoCheck);
        this.imagensForm.get(tipoCheck).reset();
    }

    verificarArquivos(tipo: string): boolean {
        return this.arquivos.length > 0 && this.arquivos.some(item => item.tipo === tipo);
    }

    _base64ToArrayBuffer(base64) {
        base64 = base64.split(',')[1];
        var binary_string = atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return Array.from(bytes);
    }

    comprimir(img?) {
        return new Promise<any>((done) => {
            let imagemOtimizada = null;
            var image = new Image();
            image.src = img;
            image.onload = () => {
                var canvas = document.createElement('canvas'),
                    ctx = canvas.getContext('2d');
                canvas.width = image.naturalWidth;
                canvas.height = image.naturalHeight;

                if (canvas.width > 1080 && canvas.width > canvas.height) {
                    let proporcao = (1080 * 100) / canvas.width / 100;
                    canvas.width = Math.round(image.naturalWidth * proporcao);
                    canvas.height = Math.round(image.naturalHeight * proporcao);
                } else if (canvas.height > 1080 && canvas.height > canvas.width) {
                    let proporcao = (1080 * 100) / canvas.height / 100;
                    canvas.height = Math.round(image.naturalHeight * proporcao);
                    canvas.width = Math.round(image.naturalWidth * proporcao);
                }

                ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                imagemOtimizada = canvas.toDataURL('image/jpeg', 0.9);
                done(imagemOtimizada);
            };
        });
    }

    async removerImagem(index) {
        let alert = await this.alertController.create({
            subHeader: 'Deseja remover esta imagem?',
            buttons: [
                { text: 'Não' },
                {
                    text: 'Sim',
                    handler: () => {
                        this.imagens = this.imagens.splice(index, 1);
                    }
                }
            ]
        });
        alert.present();
    }

    async finalizar() {
        const loading = await this.loadingController.create({
            message: 'Enviando..',
            duration: 2000,
            spinner: 'bubbles'
        });
        try {
            await loading.present();

            if (!this.autenticado) {
                await this.auth.autenticar(this.buscarAit.value.nrPlaca.replace('-', ''), this.buscarAit.value.nrRenavan);
            }
            const arquivos = [];
            for (const [index, imagem] of this.arquivos.entries()) {
                if(imagem.formato === 'jpeg') {
                    const arquivo = {
                        nmArquivo: `${imagem.tipo.substring(5)}.jpg`,
                        nmDocumento: `${imagem.tipo.substring(5)}.jpg`,
                        dtArquivamento: moment().format('YYYY-MM-DDTHH:mm:ss'),
                        blbArquivo: imagem.baixa_resolucao,
                        cdTipoArquivo: imagem.cdTipoArquivo
                    };
                    arquivos.push(arquivo);
                }
            }

            for (const [index, arquivo] of this.arquivos.entries()) {
                if(arquivo.formato === 'pdf') {
                    const arquivoPDF = {
                        nmArquivo: `${arquivo.tipo.substring(5)}.pdf`,
                        nmDocumento: `${arquivo.tipo.substring(5)}.pdf`,
                        dtArquivamento: moment().format('YYYY-MM-DDTHH:mm:ss'),
                        blbArquivo: arquivo.baixa_resolucao,
                        cdTipoArquivo: arquivo.cdTipoArquivo
                    };
                    arquivos.push(arquivoPDF);
                }
            }
            this.tratarCpfCnpjRequerente();
            let requerente = {
                cdRequerente: 0,
                nmRequerente: this.dadosCondutor.value.nmRequerente,
                nrCpfRequerente: this.dadosCondutor.value.nrCPFRequerente.replace(/\D/g,''),
                nrTelefone1Requerente: this.dadosCondutor.value.nrTelefone1Requerente.replace(/\D/g,''),
                nrCnpjRequerente: this.dadosCondutor.value.nrCnpjRequerente.replace(/\D/g,''),

                nmCondutor: this.dadosCondutor.value.nmCondutor,
                nrCpfCondutor: this.dadosCondutor.value.nrCpfCondutor.replace(/\D/g,''),
                nrCnhCondutor: this.dadosCondutor.value.nrCnhCondutor,
                nrTelefone1Condutor: this.dadosCondutor.value.nrTelefone1Condutor,
                dsEnderecoCondutor: this.dadosCondutor.value.dsEnderecoCondutor,
                ufCnhCondutor: this.dadosCondutor.value.ufCnhRequerente,
                cdCidadeCondutor: this.dadosCondutor.value.cdCidadeCondutor,
                nrCepCondutor: this.dadosCondutor.value.nrCepCondutor.replace(/\D/g,''),
                nrImovelCondutor: this.dadosCondutor.value.nrImovelCondutor,
                dsBairroCondutor: this.dadosCondutor.value.dsBairroCondutor,
            };
            let dados = {
                cdAit: this.ait.cdAit,
                cdProcesso: 0,
                dtProcesso: moment().format('YYYY-MM-DDTHH:mm:ss'),
                stProcesso: 0,
                tpProcesso: 19,
                arquivos: arquivos,
                requerente: requerente
            };
            let processo = await this.api.post(this.endpoint + '/' + this.ait.cdAit + '/processos', dados, true);

            if (processo.cdProcesso) {
                this._protocoloService.printProtocolo(processo);
            }

            let toast = await this.toastController.create({
                message: 'Apresentação realizada com sucesso',
                duration: 3000
            });
            toast.present();
            await loading.dismiss();
            this.modalController.dismiss();
        } catch (error) {
            let toast = await this.toastController.create({
                message: 'Não foi possível cadastrar este condutor',
                duration: 3000
            });
            toast.present();
            await loading.dismiss();
        }
    }

    tratarCpfCnpjRequerente(): void {
        if (this.dadosCondutor.value.nrCPFRequerente.replace(/\D/g,'').length > 11) {
             this.dadosCondutor.get("nrCnpjRequerente")?.setValue(this.dadosCondutor.value.nrCPFRequerente);
             this.dadosCondutor.get("nrCPFRequerente")?.setValue("");
        }
    }

    getTPArquivo(tipo: string) {
        let tp = this.imagensForm.value[tipo] ? this.imagensForm.value[tipo] : tipo;
        for (let tpArquivo of this.tiposArquivos) {
            if (tpArquivo.nmTipoArquivo == tp) {
                return tpArquivo.cdTipoArquivo;
            }
        }
        return 0;
    }

    setMasks(e) {
        var maskCpfCnpj = ['999.999.999-99', '99.999.999/9999-99'];
        var maskTel = ['(99) 9999-9999', '(99) 99999-9999'];
        var nrTelefone1Requerente = this.nrTelefone1Requerente.nativeElement;
        var nrCPFRequerente = this.nrCPFRequerente.nativeElement;
        var nrCpfCondutor = this.nrCpfCondutor.nativeElement;
        
        this.dadosCondutor.get('nrCPFRequerente').valueChanges.subscribe(value => {
            nrCPFRequerente.addEventListener('input', this.inputHandler.bind(undefined, maskCpfCnpj, 11), false);
        })

        this.dadosCondutor.get('nrTelefone1Requerente').valueChanges.subscribe(value => {
            nrTelefone1Requerente.addEventListener('input', this.inputHandler.bind(undefined, maskTel, 10), false);
        })
        
        VMasker(nrCpfCondutor).unMask();
        VMasker(nrCpfCondutor).maskPattern(maskCpfCnpj[0]);

        VMasker(this.nrCep.nativeElement).maskPattern('99999-999')

        if (this.stepper.selectedIndex === 2) {
            this.aviso();
        }
    }
    
    inputHandler(masks, max, event) {
        var c = event.target;
        var v = c.value.replace(/\D/g, '');
        var m = v.length > max ? 1 : 0;
        VMasker(c).unMask();
        VMasker(c).maskPattern(masks[m]);
        c.value = VMasker.toPattern(v, masks[m]);
    }

    momentFormat(date) {
        return moment(date).format('DD/MM/YYYY');
    }

    dismiss() {
        this.modalController.dismiss();
    }

    cepConsulta(e) {
        let cep = this.nrCep.nativeElement.value;
        if (cep.length == 9) {
            cep = cep.replace('-', '');
            this.viacep
                .buscarPorCep(cep)
                .then((endereco: Endereco) => {
                    this.dadosCondutor.controls['dsEnderecoCondutor'].setValue(endereco.logradouro);
                    this.dadosCondutor.controls['ufCnhRequerente'].setValue(endereco.uf);
                })
                .catch((error: ErroCep) => {});
        }
    }

    async validarApresentacao(cdAit): Promise<Boolean> {
        return new Promise(async (done) => {
            let endpoint = 'v2/mob/aits/';
            debugger
            let retorno = await this.api.get(endpoint + cdAit + '/movimentos', true);
            let infracao = await this.api.get('v2/mob/infracoes/' + this.ait.codInfracao, true);
            this.movimentos = retorno;
            let nai: Boolean = false;
            let nip: Boolean = false;
            let apresentacao: Boolean = false;
            let dtEntrada: Boolean = false;
            let tpCompetencia: Boolean = infracao.tpCompetencia === 0 ? true : false;
            let cdInfracao: Boolean = infracao.cdInfracao == 50002 || infracao.cdInfracao == 50020 ? true : false;
            let cancelamento: Boolean = false;
            
			let message: string;
			for (let movimento of this.movimentos) {
				switch (movimento.tpStatus) {
					case 3:
						nai = true;
						if (moment().isAfter(moment(movimento.dtMovimento)))
							dtEntrada = true;
						break;
					case 5:
						nip = true;
						break;
					case 19:
						apresentacao = true;
						break;
					case 17:
						cancelamento = true;
						break;
					case 25:
						cancelamento = true;
						break;
					default:
						break;
				}
			}
			if (!nai)
				message = "A apresentação do condutor só poderá ser realizada após a emissão da Notificação de Autuação de Infração - NAI";
				
			if (nip)
				message ="A apresentação do condutor só poderá ser realizada antes da emissão da Notificação de Imposição de Penalidade - NIP";
			
			if (apresentacao)
				message = "Este AIT já possui uma apresentação do condutor, verifique a movimentação na tela de andamentos de AIT";

			if (!tpCompetencia || cdInfracao)
				message = "Este tipo de infração não pode ser transferida.";

			if (cancelamento)
				message = "Esta infração foi cancelada.";
			
			if (message) {
				let toast = await this.toastController.create({
					message: message,
					buttons: [{
						side: 'start',
						icon: 'alert',
						text: 'Aviso'
					}, {
						text: 'Sair',
						role: 'cancel',
						handler: () => {
							this.modalController.dismiss();
						}
					}]
				});
				toast.present();
			}

			if (!nai || nip || apresentacao || !tpCompetencia || cdInfracao || cancelamento)
				done(true)
			else
				done(false)

            done(false);
        });
    }

    isItemAvailable = false;
    items = [];
    itemsFilter = [];

    public selecionar(cidade: any) {
        let cidadeSelected = cidade;
        this.dadosCondutor.get("nmCidade")?.setValue(cidadeSelected.nmCidade);
        this.dadosCondutor.get("cdCidadeCondutor")?.setValue(cidadeSelected.cdCidade);
        this.itemsFilter = this.items;
    }

    private async _load() {
        this.items =  await this.api2.get("/mob/portal/notificacoes/cidade" , true);
        this.dadosCondutor.get("nmCidade")?.valueChanges.pipe(startWith(""), map((value) => {
            return this._filter(value);
        })).subscribe((resp) => { this.itemsFilter = resp });
    }
    
    private _filter(value: string) {
        if (value) {
            if (typeof value == "string") {
                return this.items?.filter((v) => v.nmCidade?.indexOf(value) !== -1 || v.nmCidade
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .indexOf(value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) !== -1
                );
            }  
            if (typeof value == "string") this._load();
        }
    }

}
