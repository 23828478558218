import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Endereco, ErroCep, NgxViacepService } from '@brunoc/ngx-viacep';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import * as moment from 'moment';
import { Ait } from 'src/app/interfaces/ait';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ProtocoloService } from 'src/app/services/protocolo.service';
import { ImageCompressor } from 'src/tools/image-compressor';
import * as VMasker from 'vanilla-masker/';

@Component({
    selector: 'app-modal-recurso',
    templateUrl: './modal-recurso.page.html',
    styleUrls: ['./modal-recurso.page.scss'],
})
export class ModalRecursoPage implements OnInit {
    @ViewChild('stepper') stepper;
    @ViewChild('nrCPFRequerente', { read: ElementRef }) nrCPFRequerente: ElementRef;
    @ViewChild('nrTelefone', { read: ElementRef }) nrTelefone: ElementRef;
    @ViewChild('nrCep', { read: ElementRef }) nrCep: ElementRef;

    public tpRequerente: Array<any> = ['Condutor', 'Proprietário']

    public buscarAit: FormGroup;
    public dadosRequerente: FormGroup;
    public imagensForm: FormGroup;
    public autenticado: boolean = false;
    private tiposArquivos: Array<any>;
    public ait: Ait;
    private endpoint = "str/aits";
    public UFs = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO']
    public imagens = [];
    public arquivos = [];

    private movimentos: Array<any>;

    constructor(
        private formBuilder: FormBuilder,
        private api: ApiService,
        private auth: AuthService,
        private loadingController: LoadingController,
        private alertController: AlertController,
        private toastController: ToastController,
        private modalController: ModalController,
        private viacep: NgxViacepService,
        private _protocoloService: ProtocoloService
    ) { }

    async ngOnInit() {
        this.buscarAit = this.formBuilder.group({
            nrAit: new FormControl('', Validators.required),
            tpRequerente: new FormControl('', Validators.required),
            nrPlaca: new FormControl('', Validators.required),
            recaptchaReactive: new FormControl(null)
        })
        this.dadosRequerente = this.formBuilder.group({
            nmRequerente: new FormControl('', Validators.required),
            nrCPFRequerente: new FormControl('', Validators.compose([Validators.required, Validators.minLength(14), Validators.maxLength(18)])),
            nrTelefone: new FormControl(''),
            dsEndereco: new FormControl(''),
            nrEndereco: new FormControl(''),
            dsComplemento: new FormControl(''),
            nmMunicipio: new FormControl(''),
            ufCnhRequerente: new FormControl('')
        });

        this.imagensForm = this.formBuilder.group({
            checkRegistroCNH: new FormControl('', Validators.required),
            checkRegistroNAI: new FormControl('', Validators.required),
            checkRegistroCRLV: new FormControl('', Validators.required),
            checkRegistroID: new FormControl('', Validators.required),
            checkRegistroCompr: new FormControl('', Validators.required),
            checkRegistroForm: new FormControl('', Validators.required),
            tpRegistroCNH: new FormControl('', Validators.required),
            tpRegistroNAI: new FormControl(''),
            tpRegistroCRLV: new FormControl(''),
            tpRegistroRes: new FormControl(''),
            tpRegistroID: new FormControl('', Validators.required),
            tpRegistro: new FormControl(''),
        })

        if (!this.auth.verificar()) {
            this.autenticado = false;
        } else {
            this.autenticado = true;
            this.buscarAit.patchValue({ nrPlaca: sessionStorage.getItem('nrPlaca'), nrRenavan: sessionStorage.getItem('nrRenavan') });

        }
        try {
            this.tiposArquivos = await this.api.get('v2/grl/arquivos/tipos', true);

            if(!(this.tiposArquivos instanceof Array) || this.tiposArquivos.length === 0) {
                (await this.toastController.create({message: 'Tipos de arquivos não cadastrados, entre em contato com o suporte para a solução do problema.', buttons: [{text: 'OK'}]})).present();
                this.modalController.dismiss();
            }
        } catch (error) {
            console.error(error)
        }
    }

    async buscar(stepper: MatStepper) {
        let loading = await this.loadingController.create({ message: "Buscando AIT..." });
        loading.present();

        if (!this.autenticado) {
            await this.auth.autenticar((this.buscarAit.value.nrPlaca).replace('-', ''), this.buscarAit.value.nrRenavan)
        }
        try {
            let consulta = "placa=" + (this.buscarAit.value.nrPlaca).replace('-', '') + (this.buscarAit.value.nrAit ? "&ait=" + this.buscarAit.value.nrAit : '');
            let retorno = await this.api.get(this.endpoint + '?' + consulta, true);
            this.ait = retorno[0];
			let validacao = await this.validarRecurso(this.ait.cdAit);
			if (validacao)
				this.buscarAit.setErrors({ notValid: true })
			else
				stepper.next();

			loading.dismiss();
        } catch (error) {
            loading.dismiss();
            let toast = await this.toastController.create({ message: "Não foi possível encontrar o AIT", duration: 3000 });
            toast.present();
        }
    }

    async avancar(stepper: MatStepper) {
        stepper.next();
    }

    carregarImagens(idElemento) {
        var target = document.getElementById(idElemento)
        target.click()
    }

    async aviso() {        
        let confirm = await this.alertController.create({
            header: 'Aviso',
            message: 'O envio do documento deve ser digitalizado dentre os formatos suportados (.jpeg)',
            buttons: ['OK']
       });

       (confirm).present();
    }

    async abreArquivos(e, tipo, tipoCheck) {
        var files = e.target.files;
        const imageCompressor = new ImageCompressor();
        if (!files.length) { return; }
        for (let file of files) {
            const fileSize = file.size;
            if (file.type === 'application/pdf' && fileSize > 1000000) {
                this.imagensForm.get(tipoCheck).setErrors({filePdfGrande: true});
                return;
            }
            var reader = new FileReader();
            reader.onload = async (e) => {
                if (file.type === 'application/pdf') {
                    this.imagensForm.controls[tipoCheck].setValue(true);
                    const arquivo = {
                        formato: 'pdf',
                        tipo: tipoCheck,
                        baixa_resolucao: this._base64ToArrayBuffer(e.target['result']), 
                        cdTipoArquivo: this.getTPArquivo(tipo)
                    };
                    this.arquivos.push(arquivo); 
                } else {
                    const loading = await this.loadingController.create({
                        message: 'Carregando...',
                        spinner: 'bubbles'
                    });
                    await loading.present();
                    const compressedFile = await imageCompressor.compress(file);
                    await loading.dismiss();
                    const compressedReader = new FileReader();
                    compressedReader.readAsDataURL(compressedFile);
                    compressedReader.onloadend = () => {
                        const base64data = compressedReader.result;
                        this.imagensForm.controls[tipoCheck].setValue(true);
                        const imagem = {
                            formato: 'jpeg',
                            tipo: tipoCheck,
                            baixa_resolucao: this._base64ToArrayBuffer(base64data),
                            cdTipoArquivo: this.getTPArquivo(tipo)
                        };
                        this.arquivos.push(imagem);
                    };
                }
            };
            reader.readAsDataURL(file);
        }
    }

    public excluirArquivo(tipoCheck: string) {
        this.arquivos = this.arquivos.filter(item => item.tipo !== tipoCheck);
        this.imagensForm.get(tipoCheck).reset();
    }

    verificarArquivos(tipo: string): boolean {
        return this.arquivos.length > 0 && this.arquivos.some(item => item.tipo === tipo);
    }

    _base64ToArrayBuffer(base64) {
        base64 = base64.split(',')[1];
        var binary_string = atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return Array.from(bytes);
    }

    comprimir(img?) {
        return new Promise<any>(done => {
            let imagemOtimizada = null;
            var image = new Image();
            image.src = img;
            image.onload = () => {
                var canvas = document.createElement('canvas'),
                    ctx = canvas.getContext('2d');
                canvas.width = image.naturalWidth;
                canvas.height = image.naturalHeight;

                if (canvas.width > 1080 && canvas.width > canvas.height) {
                    let proporcao = (((1080 * 100) / canvas.width)) / 100;
                    canvas.width = Math.round(image.naturalWidth * proporcao);
                    canvas.height = Math.round(image.naturalHeight * proporcao);

                } else if (canvas.height > 1080 && canvas.height > canvas.width) {
                    let proporcao = (((1080 * 100) / canvas.height)) / 100;
                    canvas.height = Math.round(image.naturalHeight * proporcao);
                    canvas.width = Math.round(image.naturalWidth * proporcao);
                }
                ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                imagemOtimizada = canvas.toDataURL("image/jpeg", 0.9);
                done(imagemOtimizada);
            };
        });
    }

    getTPArquivo(tipo: string) {
        let tp = this.imagensForm.value[tipo] ? this.imagensForm.value[tipo] : tipo
        for (let tpArquivo of this.tiposArquivos) {
            if (tpArquivo.nmTipoArquivo == tp) {
                return tpArquivo.cdTipoArquivo
            }
        }
        return 0
    }

    async finalizar() {
        const loading = await this.loadingController.create({
            message: 'Enviando..',
            duration: 2000,
            spinner: 'bubbles'
        });
        try {
            await loading.present();
            if (!this.autenticado) {
                await this.auth.autenticar((this.buscarAit.value.nrPlaca).replace('-', ''), this.buscarAit.value.nrRenavan)
            }
            const arquivos = [];
            for (const [index, imagem] of this.arquivos.entries()) {
                if(imagem.formato === 'jpeg') {
                    const arquivo = {
                        nmArquivo: `${imagem.tipo.substring(5)}.jpg`,
                        nmDocumento: `${imagem.tipo.substring(5)}.jpg`,
                        dtArquivamento: moment().format('YYYY-MM-DDTHH:mm:ss'),
                        blbArquivo: imagem.baixa_resolucao,
                        cdTipoArquivo: imagem.cdTipoArquivo
                    };
                    arquivos.push(arquivo);
                }
            }

            for (const [index, arquivo] of this.arquivos.entries()) {
                if(arquivo.formato === 'pdf') {
                    const arquivoPDF = {
                        nmArquivo: `${arquivo.tipo.substring(5)}.pdf`,
                        nmDocumento: `${arquivo.tipo.substring(5)}.pdf`,
                        dtArquivamento: moment().format('YYYY-MM-DDTHH:mm:ss'),
                        blbArquivo: arquivo.baixa_resolucao,
                        cdTipoArquivo: arquivo.cdTipoArquivo
                    };
                    arquivos.push(arquivoPDF);
                }
            }
            let requerente;
            requerente = {
                cdRequerente: 0,
                nmRequerente: this.dadosRequerente.value.nmRequerente,
                nrCpfRequerente: this.dadosRequerente.value.nrCPFRequerente.replace(/\D/g,''),
                nrTelefone1Requerente: this.dadosRequerente.value.nrTelefone.replace(/\D/g,''),
                dsEnderecoRequerente: this.dadosRequerente.value.dsEndereco,
                nrEnderecoRequerente: this.dadosRequerente.value.nrEndereco,
                nmCidadeRequerente: this.dadosRequerente.value.nmMunicipio,
                ufCnhRequerente: this.dadosRequerente.value.ufCnhRequerente,
            }
            
            let dados = {
                cdAit: this.ait.cdAit,
                cdProcesso: 0,
                dtProcesso: moment().format('YYYY-MM-DDTHH:mm:ss'),
                stProcesso: 0,
                tpProcesso: 10,
                arquivos: arquivos,
                requerente: requerente
            }
            let processo = await this.api.post(this.endpoint + '/' + this.ait.cdAit + '/processos', dados, true);
            
			if(processo.cdProcesso) {
				this._protocoloService.printProtocolo(processo);
			}

            let toast = await this.toastController.create({ message: "Solicitação de recurso à JARI entregue com sucesso", duration: 3000 });
            toast.present();
            await loading.dismiss();
            this.modalController.dismiss();
            
        } catch (error) {
            console.error(error);
            let toast = await this.toastController.create({ message: "Não foi possível enviar esta solicitação.", duration: 3000 });
            toast.present();
            await loading.dismiss();
        } 
    }

    setMasks(e) {
        setTimeout(() => {
            var maskCpfCnpj = ['999.999.999-99', '99.999.999/9999-99'];
            var maskTel = ['(99) 9999-9999', '(99) 99999-9999'];
            var nrTelefone = this.nrTelefone.nativeElement as HTMLInputElement;
            var nrCPFRequerente = this.nrCPFRequerente.nativeElement as HTMLInputElement;
            var cpfValue = nrCPFRequerente.value.replace(/[^\w\s]/gi, '');

            this.dadosRequerente.get('nrCPFRequerente').valueChanges.subscribe(value => {
                nrCPFRequerente.addEventListener('input', this.inputHandler.bind(undefined, maskCpfCnpj, 11), false);
            })
            
            this.dadosRequerente.get('nrTelefone1Requerente').valueChanges.subscribe(value => {
                nrTelefone.addEventListener('input', this.inputHandler.bind(undefined, maskTel, 10), false);
            })

            VMasker(this.nrCep.nativeElement).maskPattern('99999-999')

        }, 1000);

        if(this.stepper.selectedIndex === 2) {
            this.aviso();
        }
    }

    inputHandler(masks, max, event) {
        var c = event.target;
        var v = c.value.replace(/\D/g, '');
        var m = v.length > max ? 1 : 0;
        VMasker(c).unMask();
        VMasker(c).maskPattern(masks[m]);
        c.value = VMasker.toPattern(v, masks[m]);
    }
    
    momentFormat(date) {
        return moment(date).format('DD/MM/YYYY')
    }

    dismiss() {
        this.modalController.dismiss()
    }

    cepConsulta(e) {
        let cep = this.nrCep.nativeElement.value;
        if (cep.length == 9) {
            cep = cep.replace('-', '');
            this.viacep.buscarPorCep(cep).then((endereco: Endereco) => {
                this.dadosRequerente.controls['dsEndereco'].setValue(endereco.logradouro);
                this.dadosRequerente.controls['nmMunicipio'].setValue(endereco.localidade);
                this.dadosRequerente.controls['ufCnhRequerente'].setValue(endereco.uf);
            }).catch((error: ErroCep) => {
            });
        }
    }

    validarRecurso(cdAit: number): Promise<Boolean> {
        return new Promise(async (done) => {
            let endpoint = "v2/mob/aits/"
            let retorno = await this.api.get(endpoint + cdAit + '/movimentos', true);
            this.movimentos = retorno;
            let nip: Boolean = false;
            let cetran: Boolean = false;
            let recurso: Boolean = false;
            let cancelamento: Boolean = false;

            let message: string;

            for (let movimento of this.movimentos) {
                switch (movimento.tpStatus) {
                    case 5:
                        nip = true;
                        break;
                    case 10:
                        recurso = true;
                        break;
                    case 17:
                        cancelamento = true;
                        break;
                    case 25:
                        cancelamento = true;
                        break;
                    case 51:
                        cetran = true;
                        break;
                    default:
                        break;
                }
            }

            if (!nip)
                message = "A solicitação de recurso só poderá ser realizada após a emissão da Notificação de Imposição de Penalidade - NIP";

            if (recurso)
                message = "Este AIT já possui um recurso à JARI registrado, verifique a movimentação na tela de andamentos de AIT";

            if (cancelamento)
                message = "Esta infração foi cancelada.";
            
            if(cetran)
                message ="A solicitação de recurso à JARI só poderá ser realizada antes da solicitação de recurso ao CETRAN";

            if (message) {
                let toast = await this.toastController.create({
                    message: message,
                    buttons: [{
                        side: 'start',
                        icon: 'alert',
                        text: 'Aviso'
                    }, {
                        text: 'Sair',
                        role: 'cancel',
                        handler: () => {
                            this.modalController.dismiss();
                        }
                    }]
                });
                toast.present();
            }
            if (!nip || recurso || cancelamento || cetran)
                done(true)
            else
                done(false)
        })
    }
}
